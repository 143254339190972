import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  MenuFileButtons,
  FoodMenu,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class MenuPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Menu"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[fishermanBusiness.ctas.phone]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={"100vh"}
              gutter={false}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Row>
              <h1>MENU</h1>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={"16"} textAlign={"center"}>
                <MenuFileButtons
                  files={fishermanBusiness.menuFiles}
                  button={{
                    primary: true,
                    size: "huge",
                    icon: "file pdf outline",
                  }}
                />
              </Grid.Column>
              <Grid.Column width={"16"} textAlign={"left"}>
                <FoodMenu
                  header={""}
                  centerNavigation={true}
                  categoryDisplayVariant={"HorizontalCardRow"}
                  itemDisplayVariant={"HorizontalCard"}
                  menu={{
                    schedules: allFishermanBusinessMenuSchedule.nodes,
                    categories: allFishermanBusinessMenuCategory.nodes,
                    items: allFishermanBusinessMenuItem.nodes,
                  }}
                  business={{
                    id: fishermanBusiness._id,
                    type: fishermanBusiness.type,
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <p>
                Consuming raw or undercooked meats, poultry, seafood, shellfish,
                or eggs may increase your risk of food borne illness
              </p>
            </Grid.Row>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        phone {
          text
          link
          internal
        }
      }
      menuFiles {
        url
        filename
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Menu" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessMenuSchedule {
      nodes {
        availableDays
        categories
        _id
        name
      }
    }
    allFishermanBusinessMenuCategory {
      nodes {
        items
        name
        description
        _id
      }
    }
    allFishermanBusinessMenuItem {
      nodes {
        modifierSets
        description
        _id
        name
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        variations {
          _id
          menuItem
          name
          order
          price
        }
        annotations {
          likeCount
        }
        visible
        available
      }
    }
  }
`;
